import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "../../utils/axios"; // Make sure this points to your axios config file

// Async thunk for fetching care codes
export const fetchCareCodes = createAsyncThunk('careCodes/fetchCareCodes', async () => {
  const response = await axios.get('/careCodes/getCodes');
  return response.data;
});

const careCodesSlice = createSlice({
  name: 'careCodes',
  initialState: {
    codes: [],
    isLoading: false,
    error: null
  },
  reducers: {},
  extraReducers: {
    [fetchCareCodes.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchCareCodes.fulfilled]: (state, action) => {
      state.codes = action.payload;
      state.isLoading = false;
    },
    [fetchCareCodes.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    }
  }
});

export default careCodesSlice.reducer;
