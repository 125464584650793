import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios"; // Make sure this points to your axios config file

const initialState = {
  contractList: [],
  isLoading: false,
  error: null,
  contract: null,
};

// Async thunks for API interactions
export const fetchContractList = createAsyncThunk(
  "contracts/fetchList",
  async (queryParams) => {
    const response = await axios.get("/contracts", { params: queryParams });
    return response.data;
  }
);

export const createContract = createAsyncThunk(
  "contracts/create",
  async (contractData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/contracts/contractRequest",
        contractData
      );
      if (!response.data.success) {
        return rejectWithValue(response.data.message);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data.message : error.message
      );
    }
  }
);

export const updateContract = createAsyncThunk(
  "contracts/update",
  async ({ id, contractData }) => {
    const response = await axios.patch(`/contracts/update/${id}`, contractData);
    return response.data;
  }
);

export const getContract = createAsyncThunk("contracts/getOne", async (contractId) => {
  const response = await axios.get(`/contracts/contract/${contractId}`);
  return response.data;
});

export const updateContractStatus = createAsyncThunk(
  'contracts/updateStatus',
  async ({ contractId, status }) => {
    const response = await axios.put(`/contracts/${contractId}/status`, { status });
    return response.data;
  }
);

export const getContracts = createAsyncThunk('contracts/getAll', async () => {
  const response = await axios.get('contracts/hmoContracts');
  return response.data.data;
});

// Slice definition
const contractsSlice = createSlice({
  name: "contracts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchContractList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchContractList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contractList = action.payload;
      })
      .addCase(fetchContractList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(createContract.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createContract.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contractList.push(action.payload.data); // Assuming payload contains the contract data
      })
      .addCase(createContract.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload; // Error message passed from rejectWithValue
      })
      .addCase(getContracts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getContracts.fulfilled, (state, action) => {
        console.log('Fulfilled action payload:', action.payload);
        state.loading = false;
        state.contractList = action.payload;
      })
      .addCase(getContracts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateContract.fulfilled, (state, action) => {
        const index = state.contractList.findIndex(
          (contract) => contract.id === action.payload.id
        );
        if (index !== -1) {
          state.contractList[index] = action.payload;
        }
      })
      .addCase(getContract.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getContract.fulfilled, (state, action) => {
        state.loading = false;
        state.contract = action.payload;
      })
      .addCase(getContract.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateContractStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateContractStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.contract = action.payload;
      })
      .addCase(updateContractStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default contractsSlice.reducer;
