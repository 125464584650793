import clsx from 'clsx'
import React from 'react'
import PropTypes from 'prop-types'
import { Link as ScrollLink } from 'react-scroll'
import makeStyles from '@mui/styles/makeStyles';
import { Link, Container, Typography } from '@mui/material'
import Logo from '../../Logo'

// ----------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    padding: theme.spacing(5, 0),
    position: 'relative',
    

    
  }
}))

// ----------------------------------------------------------------------

Footer.propTypes = {
  className: PropTypes.string
}

function Footer({ className }) {
  const classes = useStyles()

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Container maxWidth="lg" className={clsx(classes.root, className)}>
      <ScrollLink to="move_top">
        <Logo sx={{ mb: 1, mx: 'auto' }} />
      </ScrollLink>
      {/* <Typography variant="caption">
        <Link href="https://labcense.com/">Privacy Policy</Link> |
        <Link href="https://labcense.com/"> Terms & Conditions</Link> |
        <Link href="https://labcense.com/"> Data Protection</Link>
      </Typography> */}
      <br />
      <Typography variant="caption">
        © All rights reserved
        <br /> {getCurrentYear()} &nbsp;
        <Link href="https://neuclaims.com/">NueClaims Limited.</Link>
      </Typography>
    </Container>
  )
}

export default Footer
