import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import useAuth from "../../hooks/useAuth";

const initialState = {
  partnerHCPList: [],
  allHCPs: [],
  isLoading: false,
  error: null,
  hcp: null,
  contracts: [],
};

export const fetchHCPContracts = createAsyncThunk(
  "hcps/fetchHCPContracts",
  async (hcpId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/contracts/${hcpId}`);
      console.log("API response data:", response.data); 
      // Ensure the backend sends the correct structure or adjust here accordingly
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response
          ? error.response.data
          : { error: error.message || "An unknown error occurred" }
      );
    }
  }
);

const hcpsSlice = createSlice({
  name: "hcps",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getPartnerHCPListSuccess(state, action) {
      state.isLoading = false;
      state.partnerHCPList = action.payload;
    },
    getHCPListSuccess(state, action) {
      state.isLoading = false;
      state.allHCPs = action.payload;
    },
    getOneHCPSuccess(state, action) {
      state.isLoading = false;
      state.hcp = action.payload;
      console.log("Received hcp data:", action.payload);
    },
    updatePartnerHCPSuccess(state, action) {
      state.isLoading = false;
      state.hcp = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    addToPartnerHCPListSuccess(state, action) {
      state.isLoading = false;
      state.hcp = action.payload;
    },
    addHMOPartnerSuccess(state, action) {
      state.isLoading = false;
      state.partnerHCPList.push(action.payload); // Assuming action.payload is the new partner data
    },
    addHCPPartnerSuccess(state, action) {
      state.isLoading = false;
      state.allHCPs.push(action.payload); // Assuming action.payload is the new partner data
    },
    addPartnerHCPSuccess(state, action) {
      state.isLoading = false;
      state.partnerHCPList.push(action.payload); // Assuming action.payload is the new partner HCP data
    },
    getOnePartnerHCPSuccess(state, action) {
      state.isLoading = false;
      state.hcp = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetch HCP contracts
      .addCase(fetchHCPContracts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchHCPContracts.fulfilled, (state, action) => {
        console.log("Fetching contracts fulfilled", action.payload);
        state.isLoading = false;
        state.contracts = action.payload.data || [];
      })
      .addCase(fetchHCPContracts.rejected, (state, action) => {
        console.log("Fetching contracts failed", action.error);
        state.isLoading = false;
        state.error = action.payload.error || "Failed to fetch contracts";
      });
    // Add other cases as necessary
  },
});

export const {
  startLoading,
  getPartnerHCPListSuccess,
  getHCPListSuccess,
  getOneHCPSuccess,
  addToPartnerHCPListSuccess,
  updatePartnerHCPSuccess,
  hasError,
} = hcpsSlice.actions;

export default hcpsSlice.reducer;

export const fetchPartnerHCPList = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axios.post("/hcps/hcpList");
    console.log("fetchPartnerHCPList response:", response.data.data);
    dispatch(getPartnerHCPListSuccess(response.data.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

export const fetchHCPList = (queryParams) => async (dispatch) => {
  console.log("fetchHCPList payload:", queryParams);
  try {
    dispatch(startLoading());
    const response = await axios.post("/hcps/hcpAll");
    dispatch(getHCPListSuccess(response.data.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

export const getOneHCP = (uid) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axios.get(`/hcps/${uid}`);
    dispatch(getOneHCPSuccess(response.data.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

export const addToPartnerHCPList = (hcpUid, hmoUid) => async (dispatch) => {
  try {
    console.log(
      "Sending request to server with hcpUid:",
      hcpUid,
      "and hmoUid:",
      hmoUid
    );

    const response = await axios.post("/hcps/createPartnership", {
      hcpUid: hcpUid,
      hmoUid: hmoUid,
    });

    console.log("Received response from server:", response);

    dispatch(updatePartnerHCPSuccess(response.data.data));
  } catch (error) {
    console.error("Error:", error);
    dispatch(hasError(error.message));
  }
};
