import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
// material
import { alpha, experimentalStyled as styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton } from "@mui/material";
// components
import clsx from "clsx";
import React from "react";
import { Link as ScrollLink } from "react-scroll";
import makeStyles from '@mui/styles/makeStyles';
import { Link, Container, Typography } from "@mui/material";
import Logo from "../../components/Logo";

// ----------------------------------------------------------------------

// const DRAWER_WIDTH = 280;
// const APPBAR_MOBILE = 64;
// const APPBAR_DESKTOP = 92;

// const RootStyle = styled(AppBar)(({ theme }) => ({
//   boxShadow: "none",
//   backdropFilter: "blur(6px)",
//   WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
//   backgroundColor: alpha(theme.palette.background.default, 0.72),
//   [theme.breakpoints.up("lg")]: {
//     width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
//   },
// }));

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: theme.spacing(2, 0),
    marginTop: 50,
  },
}));

// ----------------------------------------------------------------------

DashboardFooter.propTypes = {
  className: PropTypes.string,
};

export default function DashboardFooter({ className }) {
  const classes = useStyles();
  return (
    <Container sx={{ px: 2.5, py: 3, borderTop: 1, borderColor: 'divider' }} maxWidth="sm" className={clsx(classes.root, className)}>
      <br />
      <Typography variant="caption">
        © All rights reserved | {new Date().getFullYear()} &nbsp; <br />
        <Link href="">NeuClaims Limited</Link>
      </Typography>
    </Container>
  );
}
