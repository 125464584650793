import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  otpSent: false,
  isVerifying: false,
  verified: false,
  error: null,
};

const otpManagementSlice = createSlice({
  name: "otp",
  initialState,
  reducers: {
    requestOtpStart(state) {
      state.isVerifying = true;
    },
    otpSent(state, action) {
      state.otpSent = true;
      state.isVerifying = false;
      state.error = null;
    },
    verifyOtpStart(state) {
      state.isVerifying = true;
    },
    otpVerified(state) {
      state.verified = true;
      state.isVerifying = false;
      state.error = null;
    },
    otpError(state, action) {
      state.isVerifying = false;
      state.error = action.payload;
    },
  },
});

export const {
  requestOtpStart,
  otpSent,
  verifyOtpStart,
  otpVerified,
  otpError,
} = otpManagementSlice.actions;

export default otpManagementSlice.reducer;

export const requestOtp = (userData) => async (dispatch) => {
  try {
    dispatch(requestOtpStart());
    // Simulate OTP request
    const response = await axios.post("/patients/otp/request", userData);
    dispatch(otpSent(response.data));
  } catch (error) {
    dispatch(otpError(error.message));
  }
};

// export const validateOtp = (otpCode) => async (dispatch) => {
//   try {
//     dispatch(verifyOtpStart());
//     // Simulate OTP validation
//     const response = await axios.post("/patients/otp/validate", { otp: otpCode });
//     if (response.data.valid) {
//       dispatch(otpVerified());
//     } else {
//       throw new Error("Invalid OTP");
//     }
//   } catch (error) {
//     dispatch(otpError(error.message));
//   }
// };

export const validateOtp = ({ userId, otpCode }) => async (dispatch) => {
  console.log("Sending OTP validation request with:", { userId, otp: otpCode });
  try {
    dispatch(verifyOtpStart()); // Dispatch an action to indicate OTP verification has started
    // Make the API call with both userId and otpCode
    const response = await axios.post("/patients/otp/validate", {
      userId,
      otp: otpCode,
    });
    console.log("OTP validation response:", response.data);

    // Check the response from the server
    if (response.data.verified) {
      dispatch(otpVerified()); // Dispatch an action when OTP is verified
      return { isValid: true }; // Return an object indicating success
    } else {
      throw new Error("Invalid OTP"); // Throw an error if the OTP is not valid
    }
  } catch (error) {
    dispatch(otpError(error.message)); // Dispatch an action if there's an error in the process
    return { isValid: false, error: error.message }; // Return an object indicating failure and the error message
  }
};
