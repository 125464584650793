import { Link as ScrollLink } from 'react-scroll'
import { useLocation } from 'react-router-dom'
import { Outlet } from "react-router-dom";

// material
import { Box, Link, Container, Typography } from '@mui/material'
// components
import Logo from '../../components/Logo'
//
import MainNavbar from './MainNavbar'
import MainFooter from './MainFooter'

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation()
  const isHome = pathname === '/'

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <>
      <MainNavbar />
      <div>
        <Outlet />
      </div>

      {!isHome ? (
        <MainFooter />
      ) : (
        <Box
          sx={{
            py: 1,
            textAlign: 'center',
            position: 'relative',
            bgcolor: 'background.default'
          }}
        >
          <Container maxWidth="lg">
            <ScrollLink to="move_top" spy smooth>
              <Logo sx={{ mb: 0, mx: 'auto', cursor: 'pointer' }} />
            </ScrollLink>

            {/* <Typography variant="caption">
              <Link href="https://labcense.com/">Privacy Policy</Link> |
              <Link href="https://labcense.com/"> Terms & Conditions</Link> |
              <Link href="https://labcense.com/"> Data Protection</Link>
            </Typography>
            <br /> */}
            <Typography variant="caption">
            {getCurrentYear()} | © All rights reserved
              <br /> &nbsp;
              {/* <Link href="https://neuclaims.com/">NeuClaims Technologies Limited.</Link> */}
            </Typography>
          </Container>
        </Box>
      )}
    </>
  )
}
