import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import messageReducer from "./slices/messageSlice";
import userReducer from "./slices/user";
import orderReducer from "./slices/order";
import patientReducer from "./slices/patients";
import claimsReducer from "./slices/claims";
import contractsReducer from "./slices/contracts";
import careCodesReducer from "./slices/careCodes";
import searchPatientReducer from "./slices/patientSearch";
import hcpReducer from "./slices/hcps";
import hcpPatientListReducer from "./slices/hcpPatientList";
import hmoReducer from "./slices/hmos";
import otpManagementReducer from "./slices/otpManagement";
import kanbanReducer from "./slices/kanban";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const productPersistConfig = {
  key: "product",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

// const hmoPersistConfig = {
//   key: "hmo",
//   storage,
//   keyPrefix: "redux-",
//   whitelist: ["uid", "hmoName"],
// };

// const hcpPersistConfig = {
//   key: "hcp",
//   storage,
//   keyPrefix: "redux-",
//   whitelist: ["uid", "hcpName", "contracts"],
// };

const hcpPersistConfig = {
  key: "hcp",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const hmoPersistConfig = {
  key: "hmo",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  message: messageReducer,
  user: userReducer,
  order: orderReducer,
  otpManagement: otpManagementReducer,
  patient: patientReducer,
  contracts: contractsReducer,
  careCodes: careCodesReducer,
  claim: claimsReducer,
  searchPatient: searchPatientReducer,
  hcp: persistReducer(hcpPersistConfig, hcpReducer),
  hmo: persistReducer(hmoPersistConfig, hmoReducer),
  hcpPatientList: hcpPatientListReducer,
  kanban: kanbanReducer,
});

export { rootPersistConfig, rootReducer };
