// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DOCS = "/docs";
const ROOTS_DASHBOARD = "/dashboard";
const ROOTS_LAB = "/";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  register: path(ROOTS_AUTH, "/register"),
  registerHMO: path(ROOTS_AUTH, "/register-hmo"),
  registerHCP: path(ROOTS_AUTH, "/register-hcp"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  verify: path(ROOTS_AUTH, "/verify"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  quality: "/quality-standard",
  faqs: "/faqs",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,

  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
    ecommerce: path(ROOTS_DASHBOARD, "/ecommerce"),
    analytics: path(ROOTS_DASHBOARD, "/analytics"),
  },

  messages: {
    chat: path(ROOTS_DASHBOARD, "/messages"),
    all: path(ROOTS_DASHBOARD, "/mail/all/:customerid"),
  },

  kanban: path(ROOTS_DASHBOARD, "/kanban"),



  patient: {
    root: path(ROOTS_DASHBOARD, "/patient"),
    detail: path(ROOTS_DASHBOARD, "/patient/detail"),
    hcpPatientDetail: path(ROOTS_DASHBOARD, "/patient/hcpPatientDetail"),
    list: path(ROOTS_DASHBOARD, "/patient/list"),
    addPatient: path(ROOTS_DASHBOARD, "/patient/new"),
    searchPatient: path(ROOTS_DASHBOARD, "/patient/searchPatient"),
    searchPatientResult: path(ROOTS_DASHBOARD, "/patient/searchPatientResult"),
    hcpPatientList: path(ROOTS_DASHBOARD, "/patient/hcpPatientList"),
  },

  hcp: {
    root: path(ROOTS_DASHBOARD, "/hcp"),
    detail: path(ROOTS_DASHBOARD, "/hcp/detail"),
    list: path(ROOTS_DASHBOARD, "/hcp/list"),
    allHCP: path(ROOTS_DASHBOARD, "/hcp/all"),
    addHCP: path(ROOTS_DASHBOARD, "/hcp/new"),
  },

  hmo: {
    root: path(ROOTS_DASHBOARD, "/hmo"),
    contract: path(ROOTS_DASHBOARD, "/hmo/contract"),
    list: path(ROOTS_DASHBOARD, "/hmo/list"),
  },

  claims: {
    root: path(ROOTS_DASHBOARD, "/claims"),
    details: path(ROOTS_DASHBOARD, "/claims/details"),
    list: path(ROOTS_DASHBOARD, "/claims/list"),
    hmoList: path(ROOTS_DASHBOARD, "/claims/hmoList"),
  },

  contracts: {
    root: path(ROOTS_DASHBOARD, "/contracts"),
    details: path(ROOTS_DASHBOARD, "/contracts/details"),
    list: path(ROOTS_DASHBOARD, "/contracts/list"),
  },

  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    list: path(ROOTS_DASHBOARD, "/user/list"),
    newUser: path(ROOTS_DASHBOARD, "/user/new"),
    editById: path(ROOTS_DASHBOARD, "/user/ada-lindgren/edit"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
  },
};

export const PATH_DOCS = {
  root: ROOTS_DOCS,
  introduction: path(ROOTS_DOCS, "/introduction"),
  quickstart: path(ROOTS_DOCS, "/quick-start"),
  package: path(ROOTS_DOCS, "/package"),

  // Theme UI
  color: path(ROOTS_DOCS, "/color"),
  typography: path(ROOTS_DOCS, "/typography"),
  icon: path(ROOTS_DOCS, "/icon"),
  shadows: path(ROOTS_DOCS, "/shadows"),
  components: path(ROOTS_DOCS, "/components"),
  settings: path(ROOTS_DOCS, "/settings"),
  tips: path(ROOTS_DOCS, "/tips"),

  // Development
  routing: path(ROOTS_DOCS, "/routing"),
  environmentVariables: path(ROOTS_DOCS, "/environment-variables"),
  stateManagement: path(ROOTS_DOCS, "/state-management"),
  apiCalls: path(ROOTS_DOCS, "/api-calls"),
  analytics: path(ROOTS_DOCS, "/analytics"),
  authentication: path(ROOTS_DOCS, "/authentication"),
  multiLanguage: path(ROOTS_DOCS, "/multi-language"),
  lazyload: path(ROOTS_DOCS, "/lazyload-image"),
  formHelper: path(ROOTS_DOCS, "/form-helper"),

  // Changelog
  support: path(ROOTS_DOCS, "/support"),
  changelog: path(ROOTS_DOCS, "/changelog"),
};

