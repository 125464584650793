import { Link as RouterLink } from "react-router-dom";
// material
import { experimentalStyled as styled } from "@mui/material/styles";
import {
  Box,
  Card,
  Stack,
  Link,
  Alert,
  Tooltip,
  Container,
  Typography,
  Button,
} from "@mui/material";
// routes
import { PATH_AUTH } from "../../routes/paths";
// hooks
import useAuth from "../../hooks/useAuth";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import { Link as ScrollLink } from 'react-scroll'
import { MHidden } from "../../components/@material-extend";
import { LoginForm } from "../../components/authentication/login";
import AuthFirebaseSocials from "../../components/authentication/AuthFirebaseSocial";
import Logo from '../../components/Logo';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  //width: "30%",
  maxWidth: 564,
  maxHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 680,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method, login } = useAuth();

  const handleLoginAuth0 = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RootStyle title="Login">
      <AuthLayout></AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h7" sx={{ px: 5, mt: 45, mb: 5 }}>
            "Efficiency is the heartbeat of effective healthcare management." -
            Someone
          </Typography>
          <div style={{ textAlign: "center" }}>
            {" "}
            <img
              src="/static/illustrations/moodboard.jpg"
              alt="login(image by freepik)"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                Sign in to your NeuClaims Account
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                Enter your login details below.
              </Typography>
            </Box>
          </Stack>

          {method === "firebase" && <AuthFirebaseSocials />}

          {method !== "auth0" ? (
            <LoginForm />
          ) : (
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleLoginAuth0}
            >
              Login
            </Button>
          )}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
